import HomeVideo from './home-video'
import HomeContent from './home-content'
import HomeBrands from './home-brands'
import HomeFooter from './home-footer'
import MVideo from './m-video'
import MContent from './m-content'
import MBrands from './m-brands'
export default {
	HomeVideo,
	HomeContent,
	HomeBrands,
	HomeFooter,
	MVideo,
	MContent,
	MBrands
}