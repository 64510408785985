<template>
	<div class="home-brands" v-if="false">
		<div style="height:10px;"></div>
		<div class="original-brands__inner">
			<div class="page-title">
				<div class="c-font__en">
					<div class="f">
						<div class="e">
							<span></span>
						</div>
						<div class="o" v-for="img, index in loop_imgs" :key="index">
							<img :class="'text-' +(index+1)" :src="img.src" :alt="img.alt" />
						</div>
					</div>
				</div>
				<div class="text-line">
					<div class="f">
						<div class="o">
							<span class=""></span>
						</div>
					</div>
				</div>
				<div class="font-line-to-top c-font__jp">
					<div class="f">
						<div class="o">
							<span class="swiper-no-swiping">{{sologan}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="original-brands__inner">
			<div class="original-brands__list">
				<ul style="margin-top: 20px;" :class="{'gray': cur_index !== -1}">
					<li v-for="logo, index in brand_logos" :key="index">
						<a href="###">
							<div class="brands" :class="logo[0]"></div>
						</a>
					</li>
				</ul>
			</div>
		</div>
		<span class="original-brands__bg">
			<span v-for="logo, index in brand_logos" :class="logo[1]" :key="index"></span>
		</span>
		<slot name="footer"></slot>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				cur_index: -1,
				brand_logos: [
					['__jd', 'original-brands__bg-jd'],
					['__jeep', 'original-brands__bg-jeep'],
					['__airChina', 'original-brands__bg-airChina'],
					['__vip', 'original-brands__bg-vip'],
					['__tencentGames', 'original-brands__bg-tencentGames'],
					['__orderPlus', 'original-brands__bg-orderPlus'],
					['__dyson', 'original-brands__bg-dyson'],
					['__wangyi', 'original-brands__bg-wangyi']
				],
				loop_imgs: [{
					alt: 'clients',
					src: '/images/pc/original_brands_page_title_c@2x.png'
				}, {
					alt: 'clients',
					src: '/images/pc/original_brands_page_title_l@2x.png'
				}, {
					alt: 'clients',
					src: '/images/pc/original_brands_page_title_i@2x.png'
				}, {
					alt: 'clients',
					src: '/images/pc/original_brands_page_title_e@2x.png'
				}, {
					alt: 'clients',
					src: '/images/pc/original_brands_page_title_n@2x.png'
				}, {
					alt: 'clients',
					src: '/images/pc/original_brands_page_title_t@2x.png'
				}, {
					alt: 'clients',
					src: '/images/pc/original_brands_page_title_s@2x.png'
				}],
				sologan: '全球3000+客户的信赖选择'
			}
		}
	}
</script>

<style>
	.home-brands {
		justify-content: space-between;
		width: 100%;
		height: 100vh;
		display: flex;
		flex-direction: column;
		min-height: 600px;
	}
</style>