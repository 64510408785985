<template>
	<div class="first-screen">
	  <div class="background-video">
	  </div>
	  <div class="title">
	    <div class="font-ol-eight">
	      <div class="f" v-show="false">
	        <div class="o" v-for="img,index in imgs" :key="index"><img :class="'text-'+(index+1)" :src="img.src" :alt="img.alt"></div>
	      </div>
		  <div class="title_1">扬帆起航</div>
		  <div class="title_2">跨境无忧</div>
	    </div>
	    <div class="text-line">
	      <div class="f">
	        <div class="o">
	          <span></span>
	        </div>
	      </div>
	    </div>
	    <div class="font-line-to-top">
	      <div class="f">
	        <div class="o">
	          <span>{{title}}</span>
	        </div>
	      </div>
	    </div>
	    <div class="player" v-if="false">
	      <p></p>
	    </div>
	  </div>
	  <div class="videoBg" style="display:none;">
	    <div class="videoBig" style="display:none;">
	      <video id="video" :src="video_src" webkit-playsinline="" playsinline="" x5-playsinline="" x-webkit-airplay="allow" preload="auto" controls="controls" controlslist="nodownload">
	              您的浏览器不支持 video 标签。
	      </video>
	    </div>
	  </div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				video_src: require('@/assets/video/media.mp4'),
				title: '技术和服务驱动的跨境整合服务商',
				imgs: [{
					src: '/images/mobile/screen1_text1@2x.png',
					alt: '智慧链接精准触达'
				},{
					src: '/images/mobile/screen1_text2@2x.png',
					alt: '智慧链接精准触达'
				},{
					src: '/images/mobile/screen1_text3@2x.png',
					alt: '智慧链接精准触达'
				},{
					src: '/images/mobile/screen1_text4@2x.png',
					alt: '智慧链接精准触达'
				},{
					src: '/images/mobile/screen1_text5@2x.png',
					alt: '智慧链接精准触达'
				},{
					src: '/images/mobile/screen1_text6@2x.png',
					alt: '智慧链接精准触达'
				},{
					src: '/images/mobile/screen1_text7@2x.png',
					alt: '智慧链接精准触达'
				},{
					src: '/images/mobile/screen1_text8@2x.png',
					alt: '智慧链接精准触达'
				}]
			}
		}
	}
</script>

<style scoped>
	.first-screen .font-ol-eight {
		overflow: hidden;
		width: auto;
	}
	.title_1 {
		/* color: rgba(255, 255, 255, 0); */
		
		text-align: center;
		font-size: 60px;
		font-weight: 900;
		text-shadow:
		    -1px -1px 0 #fff,
		    1px -1px 0 #fff,
		    -1px 1px 0 #fff,
		    1px 1px 0 #fff; /* 文本阴影 */
		line-height: 60px;
		transition-delay: 0.333333s;
		overflow: hidden;
	}
	.title_2 {
		maring-top: 10px;
		color: rgba(255, 255, 255, 0);
		text-align: center;
		font-size: 60px;
		font-weight: 900;
		text-shadow:
		    -1px -1px 0 #fff,
		    1px -1px 0 #fff,
		    -1px 1px 0 #fff,
		    1px 1px 0 #fff; /* 文本阴影 */
		transition-delay: 0.666666s;
		line-height: 60px;
		overflow: hidden;
		
	}
	
	.first-screen .font-ol-eight .title_1,
	.first-screen .font-ol-eight .title_2 {
		opacity: 0;
		transition: opacity 1s ease-in-out;
	}
	
	.active.first-screen .font-ol-eight .title_1 {
		opacity: 0.6;
	}
	
	.active.first-screen .font-ol-eight .title_2 {
		opacity: 1;
	}
</style>