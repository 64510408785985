<template>
	<div class="original-brands" v-show="false">
	  <div class="original-brands__inner">
	    <div class="page-title">
	        <div class="c-font__en">
	          <div class="f">
	            <div class="o" v-for="img,index in imgs" :key="index"><img :class="'text-'+(index+1)" :src="img.src" :alt="img.alt"></div>
	          </div>
	        </div>
	        <div class="text-line">
	          <div class="f">
	            <div class="o">
	              <span></span>
	            </div>
	          </div>
	        </div>
	        <div class="font-line-to-top c-font__jp">
	          <div class="f">
	            <div class="o">
	              <span class="swiper-no-swiping" v-html="title"></span>
	            </div>
	          </div>
	        </div>
	    </div>
	  </div>
	  <div class="original-brands__inner">
	    <div class="original-brands__list" style="margin-top:15px;">
	      <div v-for="brands, idx in brands__list" :class="brands.line_cls" :key="idx">
	        <span v-for="brand,idy in brands.brands" class="brands" :class="brand.cls" :key="idy"><img :src="brand.src" alt=""></span>
	      </div>
	    </div>
	  </div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				brands__list: [
					{
						line_cls: 'first-line',
						brands: [{
							cls: '__jd',
							src: '/images/mobile/c_jd.png'
						}, {
							cls: '__dyson',
							src: '/images/mobile/c_dyson.png'
						}]
					},
					{
						line_cls: 'second-line',
						brands: [{
							cls: '__airChina',
							src: '/images/mobile/c_airChina.png'
						}, {
							cls: '__vip',
							src: '/images/mobile/c_vip.png'
						}]
					},
					{
						line_cls: 'third-line',
						brands: [{
							cls: '__tencentGames',
							src: '/images/mobile/c_tencentGames.png'
						}, {
							cls: '__orderPlus',
							src: '/images/mobile/c_orderPlus.png'
						}]
					},
					{
						line_cls: 'fourth-line',
						brands: [{
							cls: '__jeep',
							src: '/images/mobile/c_jeep.png'
						}, {
							cls: '__wangyi',
							src: '/images/mobile/c_wangyi.png'
						}]
					}
				],
				title: '全球<b style="letter-spacing:0px;">3000+</b>客户的信赖选择',
				imgs: [{
					src: '/images/mobile/original_brands_page_title_c@2x.png',
					alt: 'clients'
				}, {
					src: '/images/mobile/original_brands_page_title_l@2x.png',
					alt: 'clients'
				}, {
					src: '/images/mobile/original_brands_page_title_i@2x.png',
					alt: 'clients'
				}, {
					src: '/images/mobile/original_brands_page_title_e@2x.png',
					alt: 'clients'
				}, {
					src: '/images/mobile/original_brands_page_title_n@2x.png',
					alt: 'clients'
				}, {
					src: '/images/mobile/original_brands_page_title_t@2x.png',
					alt: 'clients'
				}, {
					src: '/images/mobile/original_brands_page_title_s@2x.png',
					alt: 'clients'
				}, ]
			}
		}
	}
</script>

<style>
</style>