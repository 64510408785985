<template>
	<div class="animate-screen" :class="content.bg_cls">
	  <div class="content">
	    <div class="screen-bg" :class="content.mark_cls"></div>
	    <div class="text">
	      <div class="text-1 swiper-no-swiping"><span>{{content.text1}}</span></div>
	      <div class="text-2 din swiper-no-swiping"><span>{{content.text2}}</span></div>
	      <div class="text-3 swiper-no-swiping"><span><b v-html="content.text3"></b></span></div>
	      <div class="text-4 swiper-no-swiping"><span>{{content.text4}}</span></div>
	      <div class="text-button"><a :href="content.link" :target="content.link.startsWith('http') ?'_blank':'_self'">了解更多</a></div>
	    </div>
	  </div>
	</div>
</template>

<script>
	export default {
		props: {
			content: {
				type: Object,
				default: () => {}
			}
		}
	}
</script>

<style>
</style>