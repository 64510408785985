<template>
	<div>
		<template v-if="app.isMobile">
			<mobile></mobile>
		</template>
		<template v-else>
			<pc></pc>
		</template>
	</div>
</template>

<script>
	import Pc from '@/components/index/pc'
	import Mobile from '@/components/index/mobile'
	export default {
		inject: ['app'],
		components: {
			Pc,
			Mobile
		}
	}
</script>

<style>
</style>