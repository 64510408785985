<template>
	<div id="cooperation" v-cloak>
		<div class="coo_cont" ref="coo_cont" v-show="cooFlag">
			<a href="javascript:void(0);" class="close" @click="closeCooperation"></a>
			<div class="tit">
				<div class="font-ol" style="height:25;">
					<div class="f hw-panel_1_zh">
						<div class="o"><span>立</span></div>
						<div class="o"><span>即</span></div>
						<div class="o"><span>合</span></div>
						<div class="o"><span>作</span></div>
					</div>
				</div>
				<div class="font-ol" style="margin-top:11px;height:50px;font-size:65px;line-height:42px;">
					<div class="f hw-panel_1_en">
						<div class="o din"><span>C</span></div>
						<div class="o din"><span>O</span></div>
						<div class="o din"><span>O</span></div>
						<div class="o din"><span>P</span></div>
						<div class="o din"><span>E</span></div>
						<div class="o din"><span>R</span></div>
						<div class="o din"><span>A</span></div>
						<div class="o din"><span>T</span></div>
						<div class="o din"><span>I</span></div>
						<div class="o din"><span>O</span></div>
						<div class="o din"><span>N</span></div>
					</div>
				</div>
			</div>
			<div class="form_group">
				<div class="item_group">
					<input type="text" v-model="username" @focus="getFocusFn($event)" @blur="loseFocuseFn($event)">
					<label for="" ref="username">姓名</label>
					<p>{{nameErrorMsg}}</p>
				</div>
				<div class="item_group">
					<input type="text" v-model="company" @focus="getFocusFn($event)" @blur="loseFocuseFn($event)">
					<label for="" ref="job">公司及职务</label>
					<p>{{companyErrorMsg}}</p>
				</div>
			</div>
			<div class="form_group">
				<div class="item_group">
					<input type="text" v-model="mobilephone" @focus="getFocusFn($event)" @blur="loseFocuseFn($event)">
					<label for="" ref="mobile">电话</label>
					<p>{{telErrorMsg}}</p>
				</div>
				<div class="item_group">
					<input type="text" v-model="email" @focus="getFocusFn($event)" @blur="loseFocuseFn($event)">
					<label for="" ref="email">邮箱</label>
					<p>{{emailErrorMsg}}</p>
				</div>
			</div>
			<div class="form_group" v-if="false">
				<div class="item_group_area">
					<h3 class="group_title">需求方向</h3>
					<!-- <div class="btnGroup">
	                    <a href="javascript:void(0);" v-for="(item,index) in areaArr" :class="{ selected: item.selected }" @click="selectAreaFn($event,index)">{{item.text}}</a>
	                </div> -->
					<div class="checkbox_group flex__row">
						<div class="item_checkbox flex__row" v-for="(item,index) in areaArr"
							@click="checkBoxFn($event,index)">
							<div class="chuhai">
								<input type="checkbox" :name="`chuhai${index}`" :id="`chuhai${index}`" v-if="false" />
								<label :for="`chuhai${index}`"></label>
							</div>
							<span>{{item.text}}</span>
						</div>
					</div>


					<p>{{needErrorMsg}}</p>
				</div>
			</div>
			<div class="form_group">
				<div style="width:100%;overflow:hidden;">
					<div class="item_group" style="width:100%">
						<input class="needDes" type="text" v-model="otherInput" @click="otherNeedErrorMsg = '' "
							@focus="getFocusFn($event)" @blur="loseFocuseFn($event)">
						<label for="" ref="need">业务需求</label>
					</div>

					<div class="item_group">
						<p>{{otherNeedErrorMsg}}</p>
					</div>
				</div>
			</div>
			<div class="text-button">
				<a href="javascript:void(0);" class="tijiao flex__row_center" @click="tijiao()">
					<p>
						<strong>{{tijiaoTip}}</strong>
						<span v-show="loadingFlag"></span>
						<span v-show="loadingFlag"></span>
						<span v-show="loadingFlag"></span>
					</p>
				</a>
			</div>
		</div>
		<div class="success_cont" v-show="successFlag">
			<a href="javascript:void(0);" class="close" @click="closeCooperation('final')"></a>
			<img src="https://bmc-global.bluemediacdn.com/www/tijiao_success.png" width="137">
			<h3>提交成功</h3>
			<p>感谢您的来信，我们的工作人员会尽快联系您。</p>
			<div class="text-button">
				<a href="javascript:void(0);" class="final" @click="closeCooperation('final')">完成</a>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				cooFlag: true, //控制form表单隐藏显示
				successFlag: false, //控制提交成功隐藏显示

				//错误信息提示
				nameErrorMsg: '',
				companyErrorMsg: '',
				telErrorMsg: '',
				emailErrorMsg: '',
				jobErrorMsg: '',
				needErrorMsg: '',
				otherNeedErrorMsg: '',

				//表单val
				username: '',
				mobilephone: '',
				email: '',
				company: '',
				job: '',
				need: false,

				judgeArr: [],
				tijiaoTip: '提交',
				loadingFlag: false,

				areaArr: [{
						text: '出海营销',
						selected: false
					},
					{
						text: '品牌营销',
						selected: false
					},
					{
						text: '效果营销',
						selected: false
					},
					{
						text: '其他',
						selected: false
					},
				],
				otherInput: '',
				otherInputFlag: false,
				focusState: false
			}
		},
		directives: {
			focus: {
				update: function(el, {
					value
				}) {
					if (value) {
						el.focus()
					}
				}
			}
		},
		mounted() {
			bfcMedia.addCopyDownToTopAni('#cooperation', 'common1')
		},
		methods: {
			checkBoxFn: function(e, index) {
				this.needErrorMsg = "";
				var ele = "",
					bg = "";
				if (e.target.className == "item_checkbox flex__row") {
					ele = e.target.children[0].children[0]
				} else if (e.target.className == "chuhai") {
					ele = e.target.children[0]
				} else if (e.target.tagName == 'INPUT') {
					ele = e.target.nextSibling.nextSibling
				} else if (e.target.tagName == 'LABEL') {
					ele = e.target
				} else {
					ele = e.target.previousSibling.children[0]
				}
				bg = ele.style.background;
				if (bg == 'rgb(46, 47, 54)') {
					ele.style.background = '#fff';
					this.areaArr[index].selected = false;
				} else {
					ele.style.background = '#2E2F36FF';
					this.areaArr[index].selected = true;
				}
			},
			getFocusFn: function(e) {
				var el = e.target;
				el.nextSibling.style.top = "2px";
				el.nextSibling.style.color = "#878787FF";
				el.parentNode.className += " w_item_group";
				this.nameErrorMsg = '';
				this.companyErrorMsg = '';
				this.telErrorMsg = '';
				this.emailErrorMsg = '';
				this.jobErrorMsg = '';
				this.needErrorMsg = '';

				this.focusState = false;
			},
			loseFocuseFn: function(e) {
				var el = e.target;
				//el.nextSibling.nextSibling.style.top="41px";
				el.parentNode.className = "item_group"
				if (el.value == "") {
					el.nextSibling.style.top = "25px";
					el.nextSibling.style.color = "#2E2F36FF";
				} else {
					el.nextSibling.style.color = "#AAAAAA";
				}

			},
			tijiao: function() {
				var _this = this;
				/*if(this.judgeArr.join('') == '22222') {
				    alert('请勿重复提交！ | 重新提交数据，请刷新页面');
				    return;
				}*/

				if (this.username == '') {
					this.nameErrorMsg = '请填写您的姓名';
					this.judgeArr[0] = 0;
					return;
				};
				this.judgeArr[0] = 1;

				if (this.company == '') {
					this.companyErrorMsg = '请填写公司及职务';
					this.judgeArr[1] = 0;
					return;
				};
				this.judgeArr[1] = 1;

				if (this.mobilephone == '') {
					this.telErrorMsg = '请填写您的手机号码';
					this.judgeArr[2] = 0;
					return;
				} else if (!/^(13\d|14[57]|15[012356789]|17[03678]|18\d)\d{8}$/g.test(this.mobilephone)) {
					this.telErrorMsg = '手机号格式有误';
					this.judgeArr[2] = 0;
					return;
				};
				this.judgeArr[2] = 1;
				if (this.email == '') {
					this.emailErrorMsg = '请填写您的邮箱地址';
					this.judgeArr[3] = 0;
					return;
				} else if (!/^([a-zA-Z0-9_\+\-\.])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})$/g.test(this.email)) {
					this.emailErrorMsg = '邮箱格式有误';
					this.judgeArr[3] = 0;
					return;
				}
				this.judgeArr[3] = 1;
				var str = [];
				this.areaArr.forEach(function(val, index) {
					if (val.selected) {
						this.need = true;
						str.push(val.text);
					}
				}.bind(this));
				this.need = true;
				if (!this.need) {
					this.needErrorMsg = '请选择您感兴趣的需求方向';
					this.judgeArr[4] = 0;
					return;
				} else {
					// str += this.otherInput;
					this.judgeArr[4] = 1;
				}
				if (this.otherInput == '') {
					this.otherNeedErrorMsg = '请填写您的业务需求';
					this.judgeArr[5] = 0;
					return;
				};
				this.judgeArr[5] = 1;

				// 数据完整，提交数据
				if (this.judgeArr.join('') == '111111') {
					var _this = this;
					_this.tijiaoTip = '提交中';
					_this.loadingFlag = true;
					
					var body = {
						"msg_type": "interactive",
						"card": {
							"elements": [{
								"tag": "div",
								"text": {
									"content": "***姓名: ***" + this.username,
									"tag": "lark_md"
								}
							}, {
								"tag": "div",
								"text": {
									"content": "***公司及职务: ***" + this.company,
									"tag": "lark_md"
								}
							}, {
								"tag": "div",
								"text": {
									"content": "***电话: ***" + this.mobilephone,
									"tag": "lark_md"
								}
							}, {
								"tag": "div",
								"text": {
									"content": "***邮箱: ***" + this.email,
									"tag": "lark_md"
								}
							}, {
								"tag": "div",
								"text": {
									"content": "***需求方向: ***" + str.join(','),
									"tag": "lark_md"
								}
							}, {
								"tag": "div",
								"text": {
									"content": "***业务需求: ***" + this.otherInput,
									"tag": "lark_md"
								}
							}]
						}
					}
					
					fetch("https://open.feishu.cn/open-apis/bot/v2/hook/9914ef24-213f-4c92-9405-2afbe087f29b", {
						"body": JSON.stringify(body),
						"method": "POST",
						"mode": "cors"
					}).then((response) => {
						if (response.status == 200) {
							_this.tijiaoTip = '提交';
							_this.loadingFlag = false;
							_this.cooFlag = false;
							_this.successFlag = true;
							_this.judgeArr = [2, 2, 2, 2, 2, 2];
						} else {
							_this.loadingFlag = false;
							_this.tijiaoTip = "重新提交";
						}
					}).catch(error => {
						console.log(error)
					})
				}
			},
			closeCooperation: function(type) {
				var coo_contEle = this.$refs.coo_cont,
					el_username = this.$refs.username,
					el_job = this.$refs.job,
					el_mobile = this.$refs.mobile,
					el_email = this.$refs.email;

				if (type == "final") {
					this.successFlag = false;
					this.username = '';
					this.company = '';
					this.mobilephone = '';
					this.email = '';
					this.job = '';
					this.need = '';
					el_username.style.top = "25px";
					el_username.style.color = "#222";
					el_job.style.top = "25px";
					el_job.style.color = "#222";
					el_mobile.style.top = "25px";
					el_mobile.style.color = "#222";
					el_email.style.top = "25px";
					el_email.style.color = "#222";
					$(".item_checkbox .chuhai label").css('background', "#fff")
					this.areaArr.forEach(function(val, index) {
						this.$set(val, 'selected', false)
					}.bind(this));
					this.otherInput = '';
					this.otherInputFlag = false;
					this.focusState = false;
					this.cooFlag = true;
				}

				if (this.otherInputFlag) {
					coo_contEle.style.height = "586px";
				} else {
					coo_contEle.style.height = "570px";
				}

				// this.$emit('closeCooperation',false);
				$("#cooperation").hide();
			}
		}
	}
</script>

<style>
</style>