<template>
	<div>
		<div class="background-video">
			<div class="overlay-black"></div>
			<img :src="video_poster" alt="" style="width:100%;height:100%;position: absolute;left: 0;top: 0;z-index: 0;object-fit:cover;">
			<!-- <video autoplay="" loop="" muted="" :poster="video_poster">
				<source :src="video_src" type="video/mp4">
			</video> -->
		</div>
		<div class="title">
			<div class="font-ol-eight">
				<div class="f" v-show="false">
					<div class="o" v-for="img, index in loop_imgs"><img :class="'text-'+(index+1)"
							:src="img.src"
							:alt="img.alt"></div>
				</div>
				<div class="title_1">扬帆起航</div>
				<div class="title_2">跨境无忧</div>
			</div>
			<div class="text-line">
				<div class="f">
					<div class="o">
						<span></span>
					</div>
				</div>
			</div>
			<div class="font-line-to-top">
				<div class="f">
					<div class="o">
						<span>{{title}}</span>
					</div>
				</div>
			</div>
			<div class="tips-action">
				<img v-for="tip, index in tips" :class="tip.img_cls" :src="tip.src"
					:alt="tip.alt" :key="index">
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tips: [{
					img_cls: 'mouse',
					src: '/images/pc/screen1_mouse@2x.png',
					alt: '翻页提示'
				}, {
					img_cls: 'arrow_b',
					src: '/images/pc/screen1_arrow_b@2x.png',
					alt: '翻页提示'
				}, {
					img_cls: 'arrow_l',
					src: '/images/pc/screen1_arrow_l@2x.png',
					alt: '翻页提示'
				}],
				title: '技术和服务驱动的跨境整合服务商',
				video_poster: '/images/pc/poster.jpg',
				video_src: require('@/assets/video/1.mp4'),
				loop_imgs: [{
					alt: '智慧链接精准触达',
					src: '/images/pc/screen1_text1@2x.png'
				},{
					alt: '智慧链接精准触达',
					src: '/images/pc/screen1_text2@2x.png'
				},{
					alt: '智慧链接精准触达',
					src: '/images/pc/screen1_text3@2x.png'
				},{
					alt: '智慧链接精准触达',
					src: '/images/pc/screen1_text4@2x.png'
				},{
					alt: '智慧链接精准触达',
					src: '/images/pc/screen1_text5@2x.png'
				},{
					alt: '智慧链接精准触达',
					src: '/images/pc/screen1_text6@2x.png'
				},{
					alt: '智慧链接精准触达',
					src: '/images/pc/screen1_text7@2x.png'
				},{
					alt: '智慧链接精准触达',
					src: '/images/pc/screen1_text8@2x.png'
				}]
			}
		}
	}
</script>

<style scoped>
	.font-ol-eight {
		width: auto;
	}
	.title_1 {
		color: rgba(0,0,0);
		text-align: center;
		font-size: 110px;
		font-weight: 900;
		text-shadow:
		    -1px -1px 0 #fff,
		    1px -1px 0 #fff,
		    -1px 1px 0 #fff,
		    1px 1px 0 #fff; /* 文本阴影 */
		line-height: 115px;
		height: 115px;
		transition-delay: 0.333333s;
	}
	
	.title_2 {
		color: rgba(255, 255, 255, 0);
		text-align: center;
		font-size: 110px;
		font-weight: 900;
		text-shadow:
		    -1px -1px 0 #fff,
		    1px -1px 0 #fff,
		    -1px 1px 0 #fff,
		    1px 1px 0 #fff; /* 文本阴影 */
		height: 115px;
		line-height: 115px;
		transition-delay: 0.666666s;
	}
	
	.font-ol-eight .title_1 {
		opacity: 0;
		transition: opacity 1s ease-in-out;
	}
	.font-ol-eight .title_2 {
		opacity: 0;
		transition: opacity 1s ease-in-out;
	}
	
	.swiper-slide-active .font-ol-eight .title_1 {
		opacity: 0.6;
	}
	
	.swiper-slide-active .font-ol-eight .title_2 {
		opacity: 1;
	}
	
</style>