<template>
	<div class="fullPage">
		<div class="swiper-container swiper-container-vertical">
			<div class="swiper-wrapper">
				<div class="swiper-slide first-screen">
					<home-video></home-video>
				</div>
				<div v-for="content, index in contents" class="swiper-slide animate-screen" :key="index" :class="content.slider_cls" style="height:821px">
					<home-content :content="content">
						
					</home-content>
				</div>
				<div class="swiper-slide original-brands">
					<home-footer>
						<template v-slot:footer><main-footer></main-footer></template>
					</home-footer>
				</div>
			</div>
			<div class="swiper-pagination"></div>
		</div>
		<cooperation></cooperation>
		
	</div>
</template>

<script>
	import Components from './index'
	import LayoutComponents from '@/components/layouts'
	import Cooperation from '../contact/cooperation'
	export default {
		components: {
			...Components,
			...LayoutComponents,
			Cooperation
		},
		data() {
			return {
				swiperInstance: null,
				contents: [{
					slider_cls: 'second-screen',
					bgClass: 'screen-blue',
					text1: '出海营销',
					text2: 'OVERSEAS MARKETING',
					text3: '我们汇聚全球领先的媒体资源，涵盖Facebook、Google、YouTube、TikTok For Business、Twitter、LinkedIn等平台，打造一站式出海营销平台。为广告主提供整合高效的海外营销解决方案，助力企业轻松走向全球。',
					text4: 'Official reseller in China authorized by Facebook, Google, YouTube, TikTok For Business, Twitter, LinkedIn and other global platforms, we’ve built a one-stop overseas marketing platform to provide high-value integrated marketing services for overseas advertisers.',
					more_link: 'javascript:void(0);',
					more_action : () => bfcMedia && bfcMedia.cooperationIsShow()
				},{
					slider_cls: 'fifth-screen',
					bgClass: 'screen-purple',
					text1: '亚马逊代运营',
					text2: 'AMAZON OPERATION SERVICES',
					text3: '我们为品牌提供全方位的亚马逊代运营服务，覆盖产品上架、店铺优化、数据分析与运营等各个环节，帮助品牌快速占领国际电商市场。无论是初创公司还是成熟品牌，我们的专家团队都会量身定制运营策略，助力业绩持续增长。',
					text4: 'Our comprehensive Amazon operation services cover everything from product listings and store optimization to data analytics and operations. Whether you\'re a startup or an established brand, we craft tailored strategies to help you thrive in the international e-commerce landscape.',
					more_link: 'javascript:void(0);',
					more_action : () => bfcMedia && bfcMedia.cooperationIsShow()
				},{
					slider_cls: 'sixth-screen',
					bgClass: 'screen-pink',
					text1: '明星品牌代运营',
					text2: 'STAR BRAND OPERATION SERVICES',
					text3: '我们与全球一线明星和网红合作，专注于品牌代运营，帮助品牌通过明星效应快速打开市场。无论是王嘉尔、权志龙，还是其他顶级网红，我们的团队都能够为客户量身打造独一无二的品牌推广策略，提升品牌知名度和销售转化。',
					text4: 'We collaborate with top-tier influencers and celebrities, including Jackson Wang, G-Dragon, and others, to provide exclusive brand management services. Through these powerful partnerships, we help brands quickly gain market visibility and achieve impressive sales conversions.',
					more_link: 'javascript:void(0);',
					more_action : () => bfcMedia && bfcMedia.cooperationIsShow()
				},{
					slider_cls: 'seventh-screen',
					bgClass: 'screen-slate-blue',
					text1: '国际物流与货运代理',
					text2: 'INTERNATIONAL LOGISTICS & FREIGHT FORWARDING',
					text3: '我们提供专业的国际物流及货运代理服务，覆盖全球主要市场。无论是小批量货物，还是大规模出口需求，我们都能确保每个环节的高效、安全、准时交付，帮助客户顺利完成跨境电商的最后一公里。',
					text4: 'We offer expert international logistics and freight forwarding services, covering all major global markets. Whether you’re dealing with small shipments or large-scale exports, our team ensures efficient, safe, and timely delivery at every stage, enabling smooth cross-border e-commerce fulfillment.',
					more_link: 'javascript:void(0);',
					more_action : () => bfcMedia && bfcMedia.cooperationIsShow()
				},{
					slider_cls: 'eighth-screen',
					bgClass: 'screen-silver',
					text1: '权威认可',
					text2: 'INDUSTRY RECOGNITION',
					text3: '作为2019年Facebook评选的跨境出海50强企业，我们在国际市场营销领域处于领先地位。我们为全球客户提供卓越的跨境营销服务，持续引领行业创新与突破。',
					text4: 'Recognized as one of Facebook’s Top 50 Global Cross-Border Marketing Agencies in 2019, we are a leader in the international marketing space, consistently delivering outstanding services and driving innovation for clients worldwide.',
					more_link: 'https://assets.kpmg.com/content/dam/kpmg/cn/pdf/zh/2019/09/leading-chinese-cross-border-brands-the-top-50.pdf'
				}]
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.loadExternalScript('/js/common.js?v='+process.env.VUE_APP_VERSION)
				this.loadExternalScript('/js/index.js?v='+process.env.VUE_APP_VERSION)
			})
		},
		methods: {
			loadExternalScript(src) {
				// 创建一个 <script> 标签
				const script = document.createElement('script');
				script.src = src;
				script.onload = () => {
					console.log('外部脚本已加载并执行');
					// 脚本加载完成后执行你需要的逻辑
				};
				script.onerror = () => {
					console.error('外部脚本加载失败');
				};
				// 将 <script> 标签插入到 <head> 或 <body> 中
				document.head.appendChild(script);
			}
		}
	}
</script>

<style>
</style>